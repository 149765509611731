import type { NextPage } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import type { ReactElement, ReactNode } from "react";
import React from "react";

import { CacheProvider, EmotionCache } from "@emotion/react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { ConfirmProvider } from "material-ui-confirm";

import SnackbarReader from "common/components/SnackbarReader";
import OpteraTheme from "common/themes/OpteraTheme";
import createEmotionCache from "common/utils/createEmotionCache";

import "common/utils/initNumeral";
import RouteGuard from "modules/auth/RouteGuard";
import "styles/globals.css";

import {
	browserTracingIntegration,
	ErrorBoundary,
	init,
	withProfiler,
} from "@sentry/react";

const clientSideEmotionCache = createEmotionCache();

init({
	dsn: "https://d218b2f6bc3543a59ac21784d416b73b@o1354667.ingest.sentry.io/6724452",
	integrations: [browserTracingIntegration()],
	environment: process.env.DEPLOY_ENV || "local",
	release: process.env.DEPLOY_ENV || "dev",
	tracesSampleRate: 0.0,
	tunnel: "/api/sentry",
});

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
	getLayout?: (page: ReactElement) => ReactNode;
};

interface MonarchAppProps extends AppProps {
	Component: NextPageWithLayout;
	emotionCache?: EmotionCache;
}

function MonarchApp({
	Component,
	pageProps,
	emotionCache = clientSideEmotionCache,
}: MonarchAppProps) {
	// Use the layout defined at the page level, if available
	const getLayout = Component.getLayout ?? ((page) => page);

	return (
		<CacheProvider value={emotionCache}>
			<Head>
				<meta
					name="viewport"
					content="initial-scale=1, width=device-width"
					title="Optera"
				/>
				<title>Optera</title>
			</Head>
			<ErrorBoundary>
				<React.StrictMode>
					<ThemeProvider theme={OpteraTheme}>
						<ConfirmProvider>
							<RouteGuard>
								<CssBaseline />
								{getLayout(<Component {...pageProps} />)}
								<SnackbarReader />
							</RouteGuard>
						</ConfirmProvider>
					</ThemeProvider>
				</React.StrictMode>
			</ErrorBoundary>
		</CacheProvider>
	);
}

export default withProfiler(MonarchApp);
